<template>
  <div class="custom-rc-searcher">
    <RCSearcher
      v-model="localValue"
      :label="label"
      :forceSelection="forceSelection"
      :floatLabel="floatLabel"
      :delay="delay"
      :suggestions="suggestions"
      :requestLength="requestLength"
      :textField="textField"
      :dropdown="dropdown"
      :requiredField="requiredField"
      :disabled="disabled"
      @search="onSearch"
      @selectOption="onSelectOption"
      @blur="$emit('blur', $event)"
      @bottomReached="$emit('bottomReached')"
      ref="rc-searcher"
    >
      <template #suggestions-action>
        <slot name="suggestions-action"></slot>
      </template>
      <template #icon>
        <slot name="icon"></slot>
      </template>
      <template #section-title="props">
        <slot name="section-title" :section="props.section"></slot>
      </template>
      <template #suggestion-item="props">
        <slot name="suggestion-item" :suggestion="props.suggestion"></slot>
      </template>
      <template #loading-container>
        <slot name="loading-container"></slot>
      </template>
    </RCSearcher>
  </div>
</template>

<script>
import RCSearcher from "rcat-components/RCSearcher";

export default {
  name: "CustomRCSearcher",
  components: {
    RCSearcher,
  },
  props: {
    value: {
      required: false,
    },
    label: String,
    floatLabel: Boolean,
    type: {
      type: String,
      default: "text",
    },
    delay: {
      type: Number,
      default: 300,
    },
    requestLength: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
      required: false,
    },
    suggestions: {
      type: Array,
      required: false,
      default: () => [],
    },
    textField: {
      type: String,
    },
    forceSelection: {
      type: Boolean,
      default: false,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value || null,
    };
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    localValue(newValue) {
      if (!newValue) {
        this.$emit("input", newValue);
      }
    },
  },
  methods: {
    onSearch(data) {
      this.$emit("search", data);
    },
    onSelectOption(data) {
      this.$emit("input", data);
      this.$emit("selectOption", data);
    },
    hideDropdown() {
      this.$refs["rc-searcher"].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .rc-searcher-container {
    width: 100%;
    border: 1px solid $color-border-container;
  }
}
</style>
