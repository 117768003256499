<template>
  <div class="form_accounts">
    <RCSearcher
      :label="label"
      v-model="selectedAccountId"
      @search="getAllAccountIds($event)"
      :delay="300"
      :suggestions="filteredAccountIds"
      :floatLabel="floatLabel"
      :icon="false"
      :forceSelection="forceSelection"
      :lengthRequest="3"
      :key="remountSearcher"
      :disabled="routePage === 'QuoteBook' ? false : !enableEditing"
      :bottomReachEvent="true"
      :loading-container="loadingAccountIds"
      textField="new_field"
      @bottomReached="getAccountsByScroll()"
      @selectOption="selectOption"
      :class="error === true ? 'missing-field' : ''"
    >
      <template #icon>
        <i class="ion ion-search-outline"></i>
      </template>
      <template #section-title="slotProps">
        <p class="searcher-dropdown searcher-dropdown--section">
          {{ slotProps.section.sectionName }}
        </p>
      </template>
      <template v-slot:suggestion-item="slotProps">
        <div class="searcher-dropdown">
          <p>{{ slotProps.suggestion.name }}</p>
        </div>
      </template>
      <template v-slot:loading-container>
        <div class="searcher-dropdown__loading">
          <b-spinner small></b-spinner>
          <p class="ml-2">{{ $t("loading") }}</p>
        </div>
      </template>
    </RCSearcher>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
import RCSearcher from "./RCComponents/CustomRCSearcher.vue";

export default {
  name: "AccountIdsSearcher",
  props: {
    remountSearcher: Number,
    enableEditing: Boolean,
    error: Boolean,
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: null,
    },
    floatLabel: {
      type: Boolean,
      default: true,
    },
    forceSelection: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RCSearcher,
  },
  data() {
    return {
      filteredAccountIds: [{ sectionName: "Customers", options: [] }],
      selectedAccountId: null,
      accountQuery: "",
      skip: 0,
      limit: 10,
      loadingAccountIds: false,
      allAccountsWereLoaded: false,
      routePage: ""
    };
  },
  created() {
    this.routePage = this.$route.name;
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.selectedAccountId = {
            name: this.value.name,
            id: this.value.id,
            new_field: this.value.name,
          };
        }
      },
    },
    selectedAccountId() {
      if (!this.selectedAccountId) {
        this.$emit("change", this.selectedAccountId);
      }
    },
  },
  methods: {
    selectOption(data) {
      this.$emit("input", data);
      this.$emit("change", data);
    },
    setInitialValues(event) {
      this.allAccountsWereLoaded = false;
      this.skip = 0;
      this.accountQuery = event.query;
    },
    async getAllAccountIds(event) {
      this.$store.commit("triggerAbortController");
      this.setInitialValues(event);
      const data = {
        account_name: event.query,
        skip: this.skip,
      };
      const response = await this.$store.dispatch("salesforce/getAllCustomers", data);
      this.filteredAccountIds[0].options = response
        ? response.map((el) => ({ ...el, label: el.name, new_field: el.name }))
        : [];
    },
    async getAccountsByScroll() {
      if (this.loadingAccountIds || this.allAccountsWereLoaded) {
        return;
      }
      this.loadingAccountIds = true;
      this.skip += this.limit;
      const data = {
        account_name: this.accountQuery,
        skip: this.skip,
      };
      const response = await this.$store.dispatch("salesforce/getAllCustomers", data);
      const formattedResponse = response
        ? response.map((el) => ({ ...el, label: el.name, new_field: el.name }))
        : [];
      this.filteredAccountIds[0].options = [
        ...this.filteredAccountIds[0].options,
        ...formattedResponse,
      ];
      if (!response.length || response.length < 10) {
        this.allAccountsWereLoaded = true;
      }
      this.loadingAccountIds = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/components/RCComponents.scss";

.searcher-dropdown {
  cursor: pointer;
  color: $color-primary-company;
  width: 90%;
  margin: auto;
  padding: 5px;
  text-align: left;
  p {
    margin: 0px;
    @include font-small-standart-text;
  }
  &--section {
    cursor: initial;
    margin: 15px 0px 0px 15px;
    text-align: left;
    color: $color-border-container;
  }
  :hover {
    background-color: #e8ecee;
  }
  &__loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
::v-deep .rc-searcher__spinner {
  top: 4px;
  right: 2%;
}
::v-deep .rc-searcher-container {
  @include input-information;
  justify-content: center;
  padding: 8px 0px 8px 5px;
  box-shadow: none;
}
::v-deep .rc-searcher-field {
  color: #043663;
  font-family: "Montserrat";
  font-size: 14px;
}

.ion-search-outline {
  font-size: 15px;
}
</style>
